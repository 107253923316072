import React from 'react';

export const PlatformCoinbasePro: React.FC = () => {
    return (
        <svg
            width="100%"
            viewBox="0 0 2500 1500"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={'coinbase-pro'}
            transform="translate(0, -36)"
        >
            <path
                fill="#0354FF"
                opacity="1.000000"
                stroke="none"
                d="
                    M1.999997,1276.937012
	                C3.248896,1274.350220 5.259028,1272.854980 5.630394,1271.026855
	                C9.925461,1249.885132 19.792709,1231.528931 34.586590,1216.187012
	                C50.402790,1199.784912 69.919189,1188.957275 92.295753,1184.399292
	                C121.527306,1178.444946 149.877518,1181.262207 176.392151,1195.926392
	                C206.004913,1212.304077 222.533386,1237.897339 229.418503,1271.223022
	                C210.727493,1271.223022 192.851379,1271.223022 174.973648,1271.223022
	                C171.558777,1265.324463 168.603683,1258.931152 164.516083,1253.370239
	                C139.207748,1218.940063 83.402321,1223.566162 64.466599,1261.843994
	                C50.808857,1289.452515 51.750084,1316.512695 70.983429,1341.483276
	                C93.476463,1370.685913 147.814484,1374.790405 170.146561,1331.024536
	                C171.645599,1328.086914 172.966736,1325.058472 174.379471,1322.053467
	                C192.204559,1322.053467 209.780869,1322.053467 228.319244,1322.053467
	                C226.270218,1336.544800 221.307922,1349.352783 213.751022,1361.090698
	                C195.354843,1389.664795 169.040787,1405.807007 135.423523,1410.378784
	                C99.922783,1415.206909 68.058327,1406.950317 40.697193,1383.605347
	                C22.701078,1368.250610 11.569353,1348.479492 6.139440,1325.464600
	                C5.547103,1322.954102 4.416260,1320.570801 2.767591,1318.064209
	                C2.000000,1304.624756 2.000000,1291.249390 1.999997,1276.937012
                    z"
            />
            <path
                fill="#0555FE"
                opacity="1.000000"
                stroke="none"
                d="
                    M2502.000000,1325.062744
	                C2501.288818,1327.218872 2500.245850,1328.354492 2499.915771,1329.669189
	                C2493.875488,1353.738159 2481.955078,1373.787476 2460.708496,1387.548584
	                C2451.636719,1393.424194 2441.644531,1397.088623 2431.090576,1398.808105
	                C2408.322754,1402.517334 2385.983887,1401.351685 2365.043457,1390.526733
	                C2345.539307,1380.444458 2332.281250,1364.499512 2324.942871,1344.094727
	                C2311.772949,1307.474731 2312.261230,1271.029053 2329.922607,1235.891846
	                C2344.267090,1207.353271 2368.655029,1193.215942 2400.019775,1190.286377
	                C2419.288574,1188.486694 2437.973145,1190.824585 2455.137207,1199.983398
	                C2481.318115,1213.953613 2495.448975,1236.663208 2501.245117,1265.736816
	                C2502.000000,1285.375122 2502.000000,1304.750244 2502.000000,1325.062744
                    M2424.200439,1218.294189
	                C2419.277832,1217.650513 2414.297607,1216.169434 2409.441895,1216.502808
	                C2383.432373,1218.288208 2363.747803,1228.552246 2353.976074,1255.993042
	                C2344.412842,1282.849487 2344.643311,1310.199219 2354.818115,1336.968018
	                C2365.838623,1365.962036 2393.838135,1377.284912 2420.842041,1372.806641
	                C2448.777344,1368.173950 2463.279297,1349.954712 2469.808838,1324.023438
	                C2473.843506,1308.000244 2473.673096,1291.479614 2471.338623,1275.399902
	                C2467.358398,1247.982422 2454.959473,1226.301758 2424.200439,1218.294189
                    z"
            />
            <path
                fill="#0253FF"
                opacity="1.000000"
                stroke="none"
                d="
                    M857.969971,1405.340576
	                C857.740906,1301.209717 857.740906,1198.003052 857.740906,1094.229858
	                C875.291199,1094.229858 892.120422,1094.229858 910.177734,1094.229858
	                C910.177734,1132.529663 910.177734,1170.865967 910.177734,1211.196045
	                C915.050903,1207.114868 918.153748,1204.333130 921.442017,1201.791138
	                C951.679993,1178.414795 985.144714,1175.966187 1019.332397,1189.777710
	                C1059.164062,1205.869385 1080.450439,1237.343994 1086.272217,1279.457642
	                C1090.678833,1311.334473 1084.541504,1341.028931 1065.790894,1367.470825
	                C1048.344727,1392.073242 1024.274780,1406.008423 994.407715,1410.348999
	                C971.495667,1413.678711 950.033691,1409.906860 930.349060,1397.817017
	                C923.419189,1393.560791 917.413391,1387.800049 910.076172,1382.008301
	                C910.076172,1390.507690 910.076172,1398.293579 910.076172,1406.465820
	                C907.456970,1406.969849 905.600891,1407.624756 903.740417,1407.637329
	                C890.414185,1407.727295 877.086548,1407.741455 863.760742,1407.624146
	                C861.902954,1407.607788 860.052734,1406.739014 857.969971,1405.340576
                    M908.977783,1286.447632
	                C907.241577,1297.479126 908.376160,1308.281250 911.700684,1318.876221
	                C924.956360,1361.119995 973.756409,1376.580200 1008.318359,1350.965454
	                C1030.639404,1334.422485 1037.268188,1310.954956 1033.487671,1284.607788
	                C1028.647217,1250.874756 1002.697449,1229.396973 969.160217,1230.383789
	                C939.358154,1231.260742 913.874695,1254.227173 908.977783,1286.447632
                    z"
            />
            <path
                fill="#0354FF"
                opacity="1.000000"
                stroke="none"
                d="
                    M1559.503784,1324.317627
	                C1552.631592,1291.583008 1556.469971,1261.024536 1574.044922,1233.478882
	                C1598.488281,1195.167969 1635.028198,1179.364258 1679.569214,1182.009155
	                C1715.639648,1184.151001 1744.407104,1200.099487 1763.931885,1231.147217
	                C1774.264160,1247.577515 1778.499634,1265.922729 1779.617432,1285.045410
	                C1780.062988,1292.669434 1779.690674,1300.341187 1779.690674,1309.137085
	                C1768.216919,1310.240356 1757.014771,1309.543823 1745.863647,1309.666870
	                C1734.540894,1309.791870 1723.215820,1309.694458 1711.891724,1309.694458
	                C1700.900757,1309.694458 1689.909790,1309.694458 1678.918823,1309.694458
	                C1667.594727,1309.694458 1656.270752,1309.694458 1644.946655,1309.694458
	                C1633.666382,1309.694458 1622.386108,1309.694458 1611.085449,1309.694458
	                C1612.134888,1333.584717 1632.306885,1358.086304 1655.814819,1362.755737
	                C1678.030396,1367.168457 1707.781250,1366.259521 1724.755249,1334.616577
	                C1742.186890,1334.616577 1759.752441,1334.616577 1778.005249,1334.616577
	                C1774.600464,1350.693970 1767.383545,1364.200806 1756.651733,1375.909546
	                C1737.310791,1397.010986 1713.136963,1407.730957 1684.783081,1410.506104
	                C1652.970093,1413.619873 1624.124634,1406.639404 1598.691284,1387.146851
	                C1578.102295,1371.367065 1565.442261,1350.374390 1559.503784,1324.317627
                    M1698.975830,1271.667358
	                C1707.268188,1271.667358 1715.560547,1271.667358 1723.849487,1271.667358
	                C1723.773682,1249.844482 1701.718384,1229.463379 1676.967285,1227.716431
	                C1644.870239,1225.451050 1618.838745,1243.300415 1614.309814,1271.667236
	                C1641.975342,1271.667236 1669.478638,1271.667236 1698.975830,1271.667358
                    z"
            />
            <path
                fill="#0354FF"
                opacity="1.000000"
                stroke="none"
                d="
                    M1288.356445,1213.679565
	                C1297.763184,1227.661621 1301.684814,1242.536743 1301.688232,1258.550415
	                C1301.694702,1288.867798 1301.690918,1319.185303 1301.691284,1349.502686
	                C1301.691406,1352.756104 1301.691406,1356.009521 1301.691406,1360.162354
	                C1310.029175,1360.162354 1317.541504,1360.162354 1325.679443,1360.162354
	                C1325.679443,1376.197388 1325.679443,1391.326904 1325.679443,1407.611694
	                C1311.095093,1407.442261 1296.528931,1409.251587 1282.257446,1405.244507
	                C1271.081787,1402.106689 1263.039551,1394.914307 1258.324463,1384.226807
	                C1257.168945,1381.608032 1256.366455,1378.833496 1255.061279,1375.176880
	                C1253.358643,1377.517944 1252.152588,1379.032837 1251.099121,1380.647095
	                C1241.707275,1395.038940 1228.166626,1403.850342 1211.852417,1407.794434
	                C1190.197632,1413.029907 1168.520020,1412.620361 1147.379150,1404.592407
	                C1110.056274,1390.420044 1094.941162,1343.155029 1117.520142,1310.947754
	                C1124.967529,1300.324341 1135.083862,1292.876831 1146.963989,1287.861816
	                C1162.385010,1281.351929 1178.574341,1278.028687 1195.169189,1276.181641
	                C1204.711548,1275.119507 1214.195312,1273.418457 1223.640503,1271.656250
	                C1227.186523,1270.994629 1230.595215,1269.464355 1233.984985,1268.124146
	                C1241.972290,1264.965820 1247.990845,1259.711060 1248.059570,1250.615723
	                C1248.132690,1240.933228 1242.189697,1234.021240 1233.930664,1230.489868
	                C1217.650513,1223.529053 1200.812500,1223.533569 1184.492676,1230.705444
	                C1174.313599,1235.178467 1168.747559,1243.273804 1164.670654,1257.252197
	                C1148.242920,1257.252197 1131.681519,1257.252197 1113.831299,1257.252197
	                C1114.201538,1253.913452 1114.220947,1250.742676 1114.937378,1247.737915
	                C1122.790161,1214.800659 1143.683716,1194.403076 1176.057373,1186.015747
	                C1199.582642,1179.921021 1223.378784,1180.253418 1246.998291,1186.276123
	                C1263.698364,1190.534424 1277.340454,1199.505371 1288.356445,1213.679565
                    M1250.329346,1316.929443
	                C1250.329346,1311.787598 1250.329346,1306.645752 1250.329346,1299.414307
	                C1240.432617,1307.393433 1230.770996,1310.581421 1220.550781,1312.139526
	                C1214.663086,1313.037109 1208.723755,1313.599121 1202.805420,1314.292236
	                C1192.192627,1315.534912 1181.881470,1317.883545 1172.398438,1323.000610
	                C1163.359741,1327.877808 1158.713623,1335.307007 1159.698853,1345.816162
	                C1160.633179,1355.782349 1167.175659,1361.101074 1175.721313,1364.219482
	                C1194.967407,1371.242920 1213.148804,1367.947998 1229.617188,1356.555664
	                C1242.559204,1347.602783 1250.094727,1335.004883 1250.329346,1316.929443
                    z"
            />
            <path
                fill="#0354FF"
                opacity="1.000000"
                stroke="none"
                d="
                    M399.800964,1405.981934
	                C359.225983,1417.141113 321.834900,1412.955688 288.592651,1387.147095
	                C264.931183,1368.776611 251.583145,1344.188599 247.580917,1314.344482
	                C244.640472,1292.417969 246.772110,1271.233643 255.392715,1250.921753
	                C270.684357,1214.891479 298.111145,1192.750854 335.965912,1184.533447
	                C364.317200,1178.379028 392.137695,1181.644775 418.252594,1195.280884
	                C448.749695,1211.205078 467.860352,1236.079102 474.935120,1269.601929
	                C482.032745,1303.233032 477.213898,1335.051147 457.341858,1363.804321
	                C443.222931,1384.233032 423.916534,1397.754761 399.800964,1405.981934
                    M315.749695,1250.819946
	                C298.460815,1271.600220 295.291107,1295.429688 303.222656,1320.263794
	                C314.906769,1356.847046 354.637878,1372.942139 388.741394,1357.426392
	                C422.573944,1342.033813 436.814270,1296.043091 415.920105,1260.854614
	                C393.680145,1223.399902 342.822052,1220.896729 315.749695,1250.819946
                    z"
            />
            <path
                fill="#0354FF"
                opacity="1.000000"
                stroke="none"
                d="
                    M616.309570,1397.586914
	                C616.309692,1327.018799 616.309692,1257.406372 616.309692,1187.050537
	                C633.382568,1187.050537 649.833984,1187.050537 667.731995,1187.050537
	                C667.731995,1194.639404 667.731995,1202.308716 667.731995,1211.185059
	                C672.439026,1206.787231 675.637756,1203.576294 679.066223,1200.632812
	                C696.183167,1185.937256 716.608459,1181.318604 738.474426,1181.853149
	                C755.341431,1182.265625 771.375366,1186.125977 785.499451,1195.919556
	                C803.786011,1208.599487 813.285217,1226.940918 817.614075,1248.060425
	                C819.532959,1257.422485 820.159241,1267.181519 820.226257,1276.768311
	                C820.503540,1316.425903 820.342529,1356.086548 820.342529,1395.746094
	                C820.342529,1399.345947 820.342529,1402.945801 820.342529,1407.118652
	                C802.975708,1407.118652 786.446899,1407.118652 768.719055,1407.118652
	                C768.585693,1403.723755 768.330261,1400.210938 768.327576,1396.697876
	                C768.298645,1359.037964 768.402893,1321.377441 768.217468,1283.718506
	                C768.186707,1277.463989 767.622864,1271.048218 766.138611,1264.993042
	                C759.469177,1237.782471 736.418274,1226.208130 710.293335,1231.255371
	                C687.957886,1235.570679 675.283813,1250.565674 670.229736,1272.155396
	                C668.809265,1278.223511 668.451355,1284.650146 668.421753,1290.916016
	                C668.256958,1325.909058 668.342529,1360.903076 668.342529,1395.896851
	                C668.342529,1399.486084 668.342529,1403.075317 668.342529,1407.165405
	                C650.855957,1407.165405 634.332886,1407.165405 616.929138,1407.165405
	                C616.720886,1404.267334 616.515198,1401.404907 616.309570,1397.586914
                    z"
            />
            <path
                fill="#0354FF"
                opacity="1.000000"
                stroke="none"
                d="
                    M1527.993896,1238.382568
	                C1528.971313,1243.056885 1529.756958,1246.921875 1530.713135,1251.626221
	                C1513.818359,1251.626221 1497.673218,1251.626221 1480.635742,1251.626221
	                C1479.983521,1250.223633 1479.069946,1248.187378 1478.097046,1246.179688
	                C1473.989502,1237.703613 1467.492798,1231.630005 1458.721802,1228.443237
	                C1443.516724,1222.918579 1428.164307,1223.371948 1412.919434,1228.531860
	                C1411.049561,1229.164795 1409.265503,1230.154663 1407.573975,1231.194092
	                C1394.195679,1239.415283 1394.257080,1256.397217 1408.361694,1263.120850
	                C1415.994995,1266.759888 1424.735596,1268.388184 1433.153320,1270.003052
	                C1449.097412,1273.061646 1465.297852,1274.861938 1481.148926,1278.303589
	                C1495.594849,1281.440186 1508.805786,1287.744263 1519.286255,1298.718262
	                C1532.883667,1312.955933 1537.132690,1330.219482 1535.069336,1349.240112
	                C1531.521118,1381.945312 1508.359497,1400.140015 1476.766846,1407.673584
	                C1451.486450,1413.702026 1425.962769,1413.353149 1400.983398,1406.029419
	                C1371.534424,1397.395020 1350.737549,1379.475220 1343.284058,1348.489502
	                C1342.897583,1346.882568 1342.506592,1345.266968 1342.285522,1343.632690
	                C1342.067871,1342.022583 1342.063599,1340.383667 1341.932007,1338.197876
	                C1358.793457,1338.197876 1375.351807,1338.197876 1392.604248,1338.197876
	                C1397.915039,1360.095093 1415.232910,1366.079834 1435.104614,1368.006592
	                C1447.114868,1369.171143 1459.127808,1368.350708 1470.310547,1363.436279
	                C1477.826294,1360.133423 1483.938110,1355.117188 1484.328491,1345.747070
	                C1484.770630,1335.134399 1480.083252,1327.504761 1468.755859,1324.344727
	                C1457.949829,1321.330200 1446.697632,1319.888062 1435.616211,1317.895752
	                C1423.872559,1315.784302 1411.962036,1314.416992 1400.374023,1311.671143
	                C1386.671875,1308.424438 1374.406494,1302.209473 1363.725220,1292.498291
	                C1342.930054,1273.592163 1339.094727,1225.342285 1369.985474,1202.016113
	                C1383.838623,1191.555420 1399.435059,1185.648193 1416.598511,1183.216797
	                C1436.963745,1180.331787 1457.103516,1180.839111 1476.699341,1187.175537
	                C1502.010620,1195.359985 1519.757690,1211.659912 1527.993896,1238.382568
                    z"
            />
            <path
                fill="#0354FF"
                opacity="1.000000"
                stroke="none"
                d="
                    M2270.209961,1277.739136
	                C2261.611572,1297.312256 2244.949463,1304.297607 2226.533936,1308.071167
	                C2215.540527,1310.323853 2204.248535,1311.119873 2191.700928,1312.751465
	                C2225.228271,1340.951294 2259.855469,1366.848633 2294.304688,1395.263794
	                C2291.093262,1395.740112 2289.055664,1396.282104 2287.012939,1396.303833
	                C2278.021484,1396.399658 2269.013428,1396.025635 2260.041992,1396.460571
	                C2254.296143,1396.739136 2249.946289,1394.818115 2245.660889,1391.243774
	                C2215.824219,1366.357422 2185.879883,1341.600342 2155.930664,1316.849487
	                C2153.535156,1314.869751 2150.843506,1313.248291 2146.515381,1310.218262
	                C2146.515381,1340.012207 2146.515381,1367.713501 2146.515381,1395.866211
	                C2135.861816,1395.866211 2126.366211,1395.866211 2116.262207,1395.866211
	                C2116.262207,1328.814697 2116.262207,1262.018921 2116.262207,1194.684082
	                C2117.102783,1194.425415 2118.297852,1193.732788 2119.489746,1193.738159
	                C2151.781494,1193.884155 2184.083252,1193.682861 2216.359375,1194.500732
	                C2230.478760,1194.858643 2243.878906,1199.388062 2255.037598,1208.571777
	                C2273.578369,1223.831055 2276.600830,1244.230713 2273.299805,1266.450439
	                C2272.768799,1270.025391 2271.412109,1273.477905 2270.209961,1277.739136
                    M2161.152100,1219.690063
	                C2156.340332,1219.807373 2151.528809,1219.924805 2147.046875,1220.034058
	                C2147.046875,1242.871582 2147.046875,1264.328979 2147.046875,1286.100098
	                C2167.703613,1286.100098 2187.651611,1286.317383 2207.588379,1285.943604
	                C2212.432129,1285.852783 2217.396240,1284.256104 2222.035156,1282.596802
	                C2239.093018,1276.494995 2244.024658,1263.130249 2243.451416,1251.284790
	                C2242.667480,1235.091919 2233.509521,1224.800415 2217.856201,1221.207520
	                C2213.371582,1220.178223 2208.658447,1219.814697 2204.041992,1219.752686
	                C2190.400391,1219.569092 2176.754639,1219.688965 2161.152100,1219.690063
                    z"
            />
            <path
                fill="#0656FF"
                opacity="1.000000"
                stroke="none"
                d="
                    M1994.880127,1193.832520
	                C2006.316040,1195.209106 2017.136963,1195.286987 2027.288818,1197.932007
	                C2050.979736,1204.104614 2064.485596,1219.985107 2067.524414,1245.592041
	                C2068.861328,1256.858765 2067.500732,1268.125732 2062.920898,1278.801270
	                C2055.250488,1296.680786 2040.497803,1305.685913 2022.576538,1309.612305
	                C2011.320801,1312.078247 1999.625610,1313.070557 1988.078979,1313.547852
	                C1972.477295,1314.192871 1956.828979,1313.708252 1940.118774,1313.708252
	                C1940.118774,1341.230225 1940.118774,1368.349487 1940.118774,1395.937256
	                C1929.150146,1395.937256 1919.261597,1395.937256 1908.833496,1395.937256
	                C1908.833496,1328.573975 1908.833496,1261.505981 1908.833496,1193.700317
	                C1937.440918,1193.700317 1965.700928,1193.700317 1994.880127,1193.832520
                    M1994.698730,1286.299683
	                C2000.595703,1285.516235 2006.692505,1285.429077 2012.348755,1283.806152
	                C2024.610229,1280.288452 2033.819702,1272.951660 2035.864380,1259.583130
	                C2038.813965,1240.297241 2029.945435,1222.744751 2005.819946,1221.774902
	                C1986.608154,1221.002441 1967.374023,1220.773193 1948.148071,1220.381958
	                C1945.591675,1220.329712 1943.025635,1220.742676 1940.373047,1220.947388
	                C1940.373047,1242.925171 1940.373047,1264.317139 1940.373047,1286.299316
	                C1958.288696,1286.299316 1975.530029,1286.299316 1994.698730,1286.299683
                    z"
            />
            <path
                fill="#0354FF"
                opacity="1.000000"
                stroke="none"
                d="
                    M523.657349,1362.000000
	                C523.657288,1318.690308 523.657288,1276.380737 523.657288,1232.872314
	                C513.144165,1232.872314 503.021149,1232.872314 492.292480,1232.872314
	                C492.292480,1216.908936 492.292480,1202.138672 492.292480,1186.833740
	                C519.823547,1186.833740 547.012024,1186.833740 574.947083,1186.833740
	                C574.947083,1260.057739 574.947083,1333.170288 574.947083,1406.991699
	                C558.229492,1406.991699 541.472290,1406.991699 523.657410,1406.991699
	                C523.657410,1392.142822 523.657410,1377.571411 523.657349,1362.000000
                    z"
            />
            <path
                fill="#0354FF"
                opacity="1.000000"
                stroke="none"
                d="
                    M561.709351,1097.442383
                	C583.354736,1106.020264 586.218506,1129.117676 579.032349,1143.388672
                	C573.923462,1153.534302 565.549683,1159.616455 553.966614,1160.248413
                	C549.040222,1160.517334 543.873657,1160.653198 539.145386,1159.500000
                	C523.673340,1155.726685 514.587341,1141.536743 516.210022,1124.703003
                	C517.732361,1108.909546 529.776001,1096.631836 545.225586,1095.815796
                	C550.419250,1095.541504 555.689026,1096.709106 561.709351,1097.442383
                z"
            />
        </svg>
    );
};
